// Quicksearch Behavior
//
// Implements behavior for quickly linking a user to relevant items to their search query.
// Useful for simplified searches to allow the user to jump directly to the item they are
// looking for.
//
// This behavior is coupled to the Navigation Behavior. The following
// markup example is recommended.
//
//     <form action="/search">
//       <input type="text" class="form-control js-quicksearch-field js-navigation-enable">
//
//       <ul class="js-quicksearch-results js-navigation-container" data-quicksearch-url="/quicksearch"></div>
//
//         # The following content should be returned by /quicksearch
//         <li class="js-navigation-item"><a href="/defunkt" class="js-navigation-open">defunkt</a></li>
//         <li class="js-navigation-item"><a href="/josh" class="js-navigation-open">josh</a></li>
//         <li class="js-navigation-item"><a href="/mojombo" class="js-navigation-open">mojombo</a></li>
//
//       </ul>
//     </form>

import {
  clear as navigationClear,
  pop as navigationPop,
  push as navigationPush,
} from '@github-ui/input-navigation-behavior'
import {onFocus, onInput} from '@github-ui/onfocus'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {requestSubmit} from '@github-ui/form-utils'

onFocus('.js-quicksearch-field', bindQuickSearchEvents)
onInput('.js-quicksearch-field', fetchResults)
on('navigation:keydown', '.js-quicksearch-results', onNavigationKeyDown)
on('submit', '.js-quicksearch-form', teardown)

on('focusout:delay', '.js-quicksearch-field', function (event) {
  const form = event.currentTarget.closest<HTMLFormElement>('form')!
  const results = form.querySelector('.js-quicksearch-results')
  if (results) {
    results.classList.remove('active')
    navigationPop(results)
  }
})

function bindQuickSearchEvents(input: Element) {
  navigationPush(((input as HTMLInputElement).form as Element).querySelector<HTMLElement>('.js-quicksearch-results')!)
}

function teardown(event: Event) {
  const results = (event.currentTarget as HTMLFormElement).querySelector('.js-quicksearch-results')
  if (results) {
    results.classList.remove('active')
    navigationPop(results)
  }
}

function onNavigationKeyDown(e: Event) {
  const event = e as CustomEvent
  const target = event.target as Element
  const form = target.closest<HTMLFormElement>('form')!
  if (event.detail.hotkey === 'Escape') {
    const results = form.querySelector<HTMLElement>('.js-quicksearch-results')!
    results.classList.remove('active')
    navigationClear(results)
  } else if (event.detail.hotkey === 'Enter' && !target.classList.contains('js-navigation-item')) {
    requestSubmit(form)
    event.preventDefault()
  }
}

let previousController: AbortController | null = null
async function fetchResults(event: Event) {
  const input = event.target as HTMLInputElement
  const queryText = input.value.replace(/^\s+|\s+$/g, '')
  const form = input.closest<HTMLElement>('form')!
  const results = form.querySelector<HTMLElement>('.js-quicksearch-results')!
  const quickSearchURL = results.getAttribute('data-quicksearch-url')!

  let html = ''
  previousController?.abort()
  const {signal} = (previousController = new AbortController())
  const headers: HeadersInit = {
    'X-Requested-With': 'XMLHttpRequest',
  }

  if (queryText.length) {
    const url = new URL(quickSearchURL, window.location.origin)
    const params = new URLSearchParams(url.search.slice(1))
    params.append('q', queryText)
    url.search = params.toString()
    form.classList.add('is-sending')
    try {
      const response = await fetch(url.toString(), {signal, headers})
      if (response.ok) html = await response.text()
    } catch {
      // ignore network errors
    }
  }
  if (signal.aborted) return
  if (html) results.innerHTML = html
  results.classList.toggle('active', queryText !== '')
  form.classList.remove('is-sending')
}
