import './github/diffs/blob-lines'
import './github/diffs/prose-diff'
import './github/diffs/linkable-line-number'

import './github/gist/gist-edit'
import './github/gist/quicksearch'
import './github/gist/task-lists'
import './github/gist/permalink-comments'

// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
observe('.js-gist-dropzone', () => {
  import('./github/gist/drag-drop')
})
